import nodeIcon from "@images/dedicated-teams/nodejs.svg";
import reactIcon from "@images/dedicated-teams/react.svg";
import reactNativeIcon from "@images/dedicated-teams/reactnative.svg";
import typescriptFullIcon from "@images/dedicated-teams/typescript-full.svg";
import flutterIcon from "@images/dedicated-teams/flutter.svg";
import kotlinIcon from "@images/dedicated-teams/kotlin.svg";
import swiftIcon from "@images/dedicated-teams/swift.svg";
import awsIcon from "@images/dedicated-teams/aws.svg";
import backgroundImage from "@images/dedicated-teams/backgroundImage.png";
import backgroundImageMobile from "@images/dedicated-teams/backgroundImageMobile.png";

export const SECOND_CATCH_UP_SECTION_TITLE = "Get in touch";
export const SECOND_CATCH_UP_SECTION_DESCRIPTION = "Complete contact form, and we will contact you within 24 hours";

export const TECHNOLOGIES = [
    {
        technologyIcon: nodeIcon,
        technologyIconAlt: "node logo",
    },
    {
        technologyIcon: reactIcon,
        technologyIconAlt: "react logo",
    },
    {
        technologyIcon: reactNativeIcon,
        technologyIconAlt: "react native logo",
    },
    {
        technologyIcon: typescriptFullIcon,
        technologyIconAlt: "typescript logo",
    },
    {
        technologyIcon: flutterIcon,
        technologyIconAlt: "flutter logo",
    },
    {
        technologyIcon: kotlinIcon,
        technologyIconAlt: "kotlin logo",
    },
    {
        technologyIcon: swiftIcon,
        technologyIconAlt: "swift logo",
    },
    {
        technologyIcon: awsIcon,
        technologyIconAlt: "aws logo",
    },
];

export const DEDICATED_TEAMS_PRELOAD_LINKS_MOBILE = [
    {
        rel: "preload",
        href: backgroundImageMobile,
        as: "image",
        fetchPriority: "high"
    }
]

export const DEDICATED_TEAMS_PRELOAD_LINKS_DESKTOP = [
    {
        rel: "preload",
        href: backgroundImage,
        as: "image",
        fetchPriority: "high"
    }
]